<template>
  <div class="property-types-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Add Property Type</h4>
          <div class="main-contents">
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Name`"
                v-model="propertyType.name"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="number"
                :label="`Interest Rate`"
                v-model="propertyType.interestRate"
                :required="true"
                pattern="^\d{1,1}[\.]{1,1}[\d]{1,2}$"
                :placeholder="`Must be a decimal value. (Eg: 0.12)`"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="number"
                :label="`Down Payment Rate`"
                v-model="propertyType.downPaymentRate"
                :required="true"
                pattern="^\d{1,1}[\.]{1,1}[\d]{1,2}$"
                :placeholder="`Must be a decimal value. (Eg: 0.12)`"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Term`"
                v-model="propertyType.term"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Code`"
                v-model="propertyType.code"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Description`"
                v-model="propertyType.description"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-select
                :label="'Property Category'"
                v-model="propertyType.propertyCategory"
                :required="true"
                :options="propertyCategories"
              >
              </fd-select>
            </div>
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Create</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import propertyCategoriesEnums from "@/enums/propertyCategory";
import { PropertyTypeModel } from "@/models";

export default {
  name: "property-types-add",
  data() {
    return {
      propertyType: {
        name: "",
        interestRate: "",
        downPaymentRate: "",
        term: "",
        code: "",
        description: "",
        propertyCategory: ""
      }
    };
  },
  computed: {
    propertyCategories() {
      const temp = [];

      for (let x of Object.entries(propertyCategoriesEnums)) {
        temp.push({
          id: x[1],
          name: x[1]
        });
      }

      return temp;
    }
  },
  methods: {
    async submitForm() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "managePropertyTypes/createPropertyTypes",
          PropertyTypeModel.postPropertyTypeDetailPayload(this.propertyType)
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "New property type created successfully."
        });
        this.$router.push({ name: "ManagePropertyTypes" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.property-types-add {
  form {
    @extend %formDesign;
  }
}
</style>